const helpCategories = [{
  id: 18,
  icon: "Announce",
  desc: "help.topic.announcement",
  slug: "pengumuman"
}, {
  id: 13,
  icon: "Regist",
  desc: "help.topic.reg_verif",
  slug: "registrasi-dan-verifikasi"
}, {
  id: 8,
  icon: "Depo",
  desc: "help.topic.deposit",
  slug: "deposit-rupiah"
}, {
  id: 14,
  icon: "Transaction",
  desc: "help.topic.transaction",
  slug: "transaksi-jual-beli"
}, {
  id: 7,
  icon: "Withdraw",
  desc: "help.topic.withdraw",
  slug: "withdrawal-rupiah"
}, {
  id: 15,
  icon: "DigAsset",
  desc: "help.topic.digital_assets",
  slug: "aset-digital"
}, {
  id: 16,
  icon: "WalletHelp",
  desc: "help.topic.wallet",
  slug: "wallet"
}, {
  id: 17,
  icon: "Secure",
  desc: "help.topic.security",
  slug: "keamanan"
}, {
  id: 99,
  icon: "Fees",
  desc: "help.topic.fees",
  slug: "fees"
}, {
  id: 1,
  icon: "Etc",
  desc: "help.topic.etc",
  slug: "lain-lain"
}];
export default helpCategories;