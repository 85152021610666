import Announce from "./announcement.svg";
import Depo from "./depo-idr.svg";
import DigAsset from "./dig-assets.svg";
import Etc from "./etc.svg";
import Fees from "./fees.svg";
import Regist from "./reg-verif.svg";
import Secure from "./secure.svg";
import Transaction from "./transaction.svg";
import WalletHelp from "./wallet-help.svg";
import Withdraw from "./withdraw-idr.svg";
export { Announce, Depo, DigAsset, Etc, Fees, Regist, Secure, Transaction, WalletHelp, Withdraw };