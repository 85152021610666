import _styled from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

/* eslint-disable react/jsx-props-no-spreading */
import { useTheme } from "@emotion/react";
import * as IconDarkComponents from "./svg/dark";
import * as IconLightComponents from "./svg/light";

const StyledIcon = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "ekela2b0"
} : {
  target: "ekela2b0",
  label: "StyledIcon"
})(process.env.NODE_ENV === "production" ? {
  name: "pl708y",
  styles: "display:inline-flex;align-items:center;justify-content:center"
} : {
  name: "pl708y",
  styles: "display:inline-flex;align-items:center;justify-content:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL21vZHVsZXMvSGVscC9JY29ucy9JY29ucy50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBTzZCIiwiZmlsZSI6Ii92ZXJjZWwvcGF0aDAvc3JjL21vZHVsZXMvSGVscC9JY29ucy9JY29ucy50c3giLCJzb3VyY2VzQ29udGVudCI6WyIvKiBlc2xpbnQtZGlzYWJsZSByZWFjdC9qc3gtcHJvcHMtbm8tc3ByZWFkaW5nICovXG5pbXBvcnQgeyB1c2VUaGVtZSB9IGZyb20gXCJAZW1vdGlvbi9yZWFjdFwiXG5pbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIlxuXG5pbXBvcnQgKiBhcyBJY29uRGFya0NvbXBvbmVudHMgZnJvbSBcIi4vc3ZnL2RhcmtcIlxuaW1wb3J0ICogYXMgSWNvbkxpZ2h0Q29tcG9uZW50cyBmcm9tIFwiLi9zdmcvbGlnaHRcIlxuXG5jb25zdCBTdHlsZWRJY29uID0gc3R5bGVkLmRpdmBcbiAgICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuYFxuXG5leHBvcnQgaW50ZXJmYWNlIEljb25zUHJvcHMgZXh0ZW5kcyBSZWFjdC5TVkdQcm9wczxTVkdTVkdFbGVtZW50PiB7XG4gICAgLyoqXG4gICAgICogSWNvbiBuYW1lXG4gICAgICovXG4gICAgaWNvbjoga2V5b2YgdHlwZW9mIEljb25MaWdodENvbXBvbmVudHNcbn1cblxuY29uc3QgSWNvbnM6IFJlYWN0LkZDPEljb25zUHJvcHM+ID0gKHsgaWNvbiwgLi4ucHJvcHMgfTogSWNvbnNQcm9wcykgPT4ge1xuICAgIGNvbnN0IHRoZW1lID0gdXNlVGhlbWUoKVxuICAgIGNvbnN0IENvbXBvbmVudCA9IHRoZW1lLm1vZGUgPT09IFwibGlnaHRcIiA/IEljb25MaWdodENvbXBvbmVudHNbaWNvbl0gOiBJY29uRGFya0NvbXBvbmVudHNbaWNvbl1cblxuICAgIHJldHVybiAoXG4gICAgICAgIDxTdHlsZWRJY29uPlxuICAgICAgICAgICAgPENvbXBvbmVudCB7Li4ucHJvcHN9IC8+XG4gICAgICAgIDwvU3R5bGVkSWNvbj5cbiAgICApXG59XG5cbkljb25zLmRlZmF1bHRQcm9wcyA9IHt9XG5cbmV4cG9ydCBkZWZhdWx0IEljb25zXG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export interface IconsProps extends React.SVGProps<SVGSVGElement> {
  /**
   * Icon name
   */
  icon: keyof typeof IconLightComponents;
}

const Icons: React.FC<IconsProps> = ({
  icon,
  ...props
}: IconsProps) => {
  const theme = useTheme();
  const Component = theme.mode === "light" ? IconLightComponents[icon] : IconDarkComponents[icon];
  return <StyledIcon>
            <Component {...props} />
        </StyledIcon>;
};

Icons.defaultProps = {};
export default Icons;